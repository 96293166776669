<template>
  <div
    ref="clickOutsideWrapper"
    class="filter-wrapper"
  >
    <div
      class="filter-input-wrapper"
      @click="toggleOptions()"
      @mouseenter="onFilterHover()"
      @mouseleave="onFilterUnhover()"
    >
      <img
        v-if="selectedOptions.length === 0"
        class="search-icon-default"
        src="@/assets/restyle/images/shared/icons/filter_search_default.svg"
        alt=""
      />
      <img
        v-if="selectedOptions.length > 0"
        @click.stop="onCrossClick()"
        class="cross"
        src="@/assets/restyle/images/shared/icons/cross_2.svg"
        alt=""
      />
      <input
        type="text"
        ref="searchInput"
        @input="inputChangeHandler($event)"
        @mouseenter="showTooltipIfOverflowing($event, computedPlaceholder)"
        @mouseleave="hideTooltip"
        :value="inputText"
        :placeholder="computedPlaceholder"
        class="input-box"
        :class="{ 'input-active': inputText,
          'placeholder-active': selectedOptions.length > 0,
          'input-box-options-selected': selectedOptions.length > 0,
        }"
      />
      <img
        v-if="optionsVisible"
        class="arrow-icon"
        src="@/assets/restyle/images/shared/icons/arrow-down-gray.svg"
        alt=""
      />
      <img
        v-if="!optionsVisible"
        class="arrow-icon"
        src="@/assets/restyle/images/shared/icons/arrow-right-gray.svg"
        alt=""
      />
    </div>
    <div
      v-if="optionsVisible"
      :style="{ 'max-height': optionsMaxHeight, 'min-width': optionsMinWidth }"
      class="select-option-wrapper scrollbar scrollbar--secondary"
    >
      <div
        v-if="filteredOptions.length > 1"
        @mouseenter="chooseAllHovered = true"
        @mouseleave="chooseAllHovered = false"
        @click="toggleAllFilteredOptions()"
        class="select-option"
      >
        <CheckboxSquareMultiselectFilter
          class="checkbox"
          :hoverColorChange="chooseAllHovered"
          :checkboxValue="chooseAll ? 'yes' : 'no'"
        />
        <span>Выбрать все</span>
      </div>
      <div
        v-for="(option, index) in filteredOptions"
        :key="index"
        @click="toggleOption(option)"
        @mouseenter="setOptionHovered(index)"
        @mouseleave="setOptionUnhovered(index)"
        class="select-option"
      >
        <div class="" :class="{'ml-3': option.itsChild}">
          <CheckboxSquareMultiselectFilter
            class="checkbox"
            :checkboxValue="isSelected(option) ? 'yes' : 'no'"
            :hoverColorChange="isOptionHovered[index]"
          />
          <div
            v-if="option.type === 'fact'"
            class="dot-icon dot-icon-green"
          >
          </div>
          <div
            v-if="option.type === 'logistic'"
            class="dot-icon dot-icon-blue"
          >
          </div>
          <span>{{ option.name }}</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import CheckboxSquareMultiselectFilter from '@/components/restyle/primitives/checkboxes/CheckboxSquareMultiselectFilter.vue';
import { getWordForm } from '@/_helpers/functions/functions.js';

export default {
  name: 'SearchFilterMultiSelect',
  components: {
    CheckboxSquareMultiselectFilter,
  },
  props: {
    inputText: {
      default: '',
      type: String,
      required: true,
    },
    placeholder: {
      default: null,
      type: String,
    },
    allSelectOptions: {
      required: true,
      type: Array
    },
    selectedOptions: {
      required: true,
      type: Array,
    },
    optionsMaxHeight: {
      default: '250px',
      type: String
    },
    wordForms: {
      type: Array,
      default: () => ['позиция', 'позиции', 'позиций'],
    },
    wordFormsSelected: {
      type: Array,
      default: () => ['Выбрана', 'Выбрано', 'Выбрано'],
    },
    showTooltip: {
      type: Boolean,
      default: false,
    },
    tooltipDelay: {
      type: Number,
      default: 500,
    },
    optionsMinWidth: {
      default: 'auto',
      type: String
    },
  },
  data() {
    return {
      isHovered: false,
      optionsVisible: false,
      isOptionHovered: [],
      chooseAllHovered: false,
      tooltipTimeout: null,
    }
  },
  computed: {
    filteredOptions() {
      if (this.inputText) {
        let lowerCasedFilter = this.inputText.toLowerCase();
        return this.allSelectOptions.filter(option => option.name.toLowerCase().includes(lowerCasedFilter));
      }
      return this.allSelectOptions;
    },
    chooseAll() {
      return this.filteredOptions.length > 0 && this.filteredOptions.every(option => this.isSelected(option));
    },
    computedPlaceholder() {
        if (this.selectedOptions.length > 0) {
          return `${getWordForm(this.selectedOptions.length, this.wordFormsSelected)}`
          + ` ${this.selectedOptions.length} `
          + `${getWordForm(this.selectedOptions.length, this.wordForms)}`;
        } else {
          return this.placeholder;
        }
    },
  },
  watch: {
    optionsVisible(newValue) {
      if (!newValue) {
        this.$emit('inputChange', '');
      }
    },
  },
  mounted() {
    document.addEventListener('click', this.clickOutsideClose);
  },
  beforeDestroy() {
    document.removeEventListener('click', this.clickOutsideClose);
    clearTimeout(this.tooltipTimeout);
  },
  methods: {
    onFilterHover() {
      this.isHovered = true;
    },
    onFilterUnhover() {
      this.isHovered = false;
    },
    clearSelect() {
      this.$emit('inputChange', '');
      this.$emit('selectedOptionsChange', []);
    },
    showOptions() {
      this.optionsVisible = true;
    },
    hideOptions() {
      this.optionsVisible = false;
    },
    toggleOptions() {
      this.optionsVisible = !this.optionsVisible;
    },
    clickOutsideClose(event) {
      if (!this.$refs.clickOutsideWrapper?.contains(event.target)) {
        this.optionsVisible = false;
      }
    },
    onCrossClick() {
      this.clearSelect();
      this.hideOptions();
    },
    isSelected(option) {
      return this.selectedOptions.some(selectedOption => selectedOption.name === option.name && selectedOption.type === option.type);
    },
    toggleOption(option) {
      if (this.isSelected(option)) {
        let result = this.selectedOptions.filter(
          selectedOption => selectedOption.name !== option.name || selectedOption.type !== option.type
        );
        this.$emit('selectedOptionsChange', result);
      } else {
        this.$emit('selectedOptionsChange', [...this.selectedOptions, option])
      }
    },
    setOptionHovered(index) {
      this.$set(this.isOptionHovered, index, true);
    },
    setOptionUnhovered(index) {
      this.$set(this.isOptionHovered, index, false);
    },
    inputChangeHandler(event) {
      if (event.target.value !== '') {
        this.optionsVisible = true;
      }
      this.$emit('inputChange', event.target.value);
    },
    toggleAllFilteredOptions() {
      if (this.chooseAll) {
        this.$emit('selectedOptionsChange', []);
      } else {
        this.$emit('selectedOptionsChange', this.filteredOptions);
      }
    },
    async showTooltipIfOverflowing(event, text) {
      if (!this.showTooltip) {
        return;
      }
      const isPlaceholderOverflowing = await this.checkPlaceholderOverflow(text);
      if (isPlaceholderOverflowing) {
        this.tooltipTimeout = setTimeout(() => {
          this.$store.dispatch("tooltip_store/SET_VIEW_TOOLTIP", {
            hoverEvent: event,
            tooltipText: text,
            tooltipPosition: 'top',
          });
        }, this.tooltipDelay)
      }
    },
    async checkPlaceholderOverflow(text) {
      const prevValue = this.inputText;
      this.$emit('inputChange', text);
      await this.$nextTick();
      const isOverflowing =  this.$refs.searchInput.scrollWidth > this.$refs.searchInput.clientWidth;
      this.$emit('inputChange', prevValue);
      return isOverflowing;
    },
    hideTooltip() {
      if (!this.showTooltip) {
        return;
      }
      clearTimeout(this.tooltipTimeout);
      this.$store.dispatch("tooltip_store/CLEAR_VIEW_TOOLTIP");
    },
  }
}
</script>

<style lang="scss" scoped>

.input-box {
  padding: 12px 32px 12px 40px;
  box-shadow: 0px 2px 8px rgba(23, 21, 55, 0.1);
  border-radius: 5px;
  background-color: $color-restyle-white;
  font-weight: 400;
  font-size: 18px;
  line-height: 25px;
  color: $color-gray-text;
  &:focus::placeholder {
    color: $color-gray-text;
  }
}

.select-box {
  padding: 12px 12px 12px 40px;
  box-shadow: 0px 2px 8px rgba(23, 21, 55, 0.1);
  border-radius: 5px;
  background-color: $color-restyle-white;
  font-weight: 400;
  font-size: 18px;
  line-height: 25px;
  color: $color-gray-text;
  width: 400px;
  height: 49px;
  cursor: pointer;
}

.input-box-options-selected {
  outline: 1px solid #466ABF;
  box-shadow: none;
}

.select-box-text {
  font-weight: 400;
  font-size: 18px;
  line-height: 25px;
  color: $color-page-text;
}

.input-active {
  color: $color-page-text;
}

.cross {
  width: 15px;
  height: 15px;
  cursor: pointer;
  position: absolute;
  top: 33%;
  left: 12px;
}

.search-icon-active,
.search-icon-default {
  width: 18px;
  height: 18px;
  position: absolute;
  top: 33%;
  left: 12px;
}

input::placeholder {
  color: $color-gray-text;
}

.filter-wrapper {
  position: relative;
  display: flex;
  align-items: center;
  // width: 300px;
}

input {
  width: 100%;
}

.select-option {
  overflow-wrap: break-word;

  &:hover {
    cursor: pointer;
  }

  padding: 10px 20px 10px 20px;

  &:first-child {
    border-radius: 9.4px 9.4px 0 0;
  }

  &:last-child {
    border-radius: 0 0 9.4px 9.4px;
  }
}

.select-option-wrapper {
  border-radius: 9.4px;
  box-shadow: 0px 3.8px 15.1px rgba(23, 21, 55, 0.1);
  background: #FFFFFF;
  position: absolute;
  z-index: 1;
  font-size: 20px;
  line-height: 27px;
  width: 100%;
  overflow-y: auto;
  max-height: 250px;
  top: 52px;
}

.arrow-icon {
  position: absolute;
  top: calc(33% + 4px);
  right: 12px;
  width: 11px;
  height: 11px;
}
.checkbox {
  display: inline-flex;
  margin-right: 10px;
  position: relative;
  bottom: 1px;
}

.dot-icon {
  width: 12px;
  height: 12px;
  border-radius: 50%;
  margin-right: 10px;
  display: inline-block;
}

.dot-icon-green {
  background-color: $color-smartro-green-bright;
}

.dot-icon-blue {
  background-color: $color-logistics;
}

.placeholder-active::placeholder {
  color: $color-page-text;
}

input {
  &:focus {
    outline: 1px solid $color-smartro-green-bright;
    box-shadow: none;
  }
}

.filter-input-wrapper:hover input {
  outline: 1px solid $color-smartro-green-bright;
  box-shadow: none;
}

.filter-input-wrapper {
  position: relative;
  width: 100%;
}
</style>