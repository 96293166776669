export const rework_online_monitoring_track_stop = {
  namespaced: true,
  state: {
    viewTrackOnMap: null,
    stateTrackStopOnMap: {
      active: false,
    },
  },
  getters: {
    getViewTrackOnMap: state => state.viewTrackOnMap,
    getStateTrackStopOnMap: state => state.stateTrackStopOnMap,
  },
  mutations: {
    setViewTrackOnMap(state, payload) {
      state.viewTrackOnMap = payload;
      console.log(`track_stop setViewTrackOnMap`, state.viewTrackOnMap);
    },
    setStateTrackStopOnMap(state, payload) {
      state.stateTrackStopOnMap = payload;
      console.log(`track_stop stateTrackStopOnMap`, state.stateTrackStopOnMap);
    },
    clearStateTrackStop(state) {
      state.viewTrackOnMap = null;
      state.stateTrackStopOnMap = {
        active: false,
      };
    }
  }
}