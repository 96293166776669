<template>
  <div 
    class="restyle-menu__mobile-wrapper"
    :style="{
      'box-shadow': isActiveScroll || isVisibleUserCreate || isVisibleUserEdit || isVisibleVehicleCreate || isVisibleVehicleEdit || isVisibleVehicleDetailPage || isVisibleInstallationKitBinding || isVisibleComponentInstKit ? '0px 2px 6px 2px #81B8EB26' : ''
    }"
  >
    <div
      class="restyle-menu__mobile"
      :class="{ 'restyle-menu__mobile--active': isVisibleMobileMenu }"
    >
      <div class="block-menu">
        <div class="block-menu__top-items">
          <div class="block-menu__top-items__left">
            <button @click="isVisibleMobileMenu = false">
              <img src="@/assets/icons/mobile_menu/arrow_back.svg" alt="arrow-back">
            </button>
          </div>
          <div class="block-menu__top-items__right">
              <img src="@/assets/icons/mobile_menu/sr_logo.svg" alt="logo" />
          </div>
        </div>
        <div v-if="userInfo !== null" class="block-menu__user-info">
            <img
              class="block-menu__user-info__img"
              src="@/assets/icons/mobile_menu/avatar_picture.svg"
              alt="avatar">
            <p class="block-menu__user-info__text">
              {{ userInfo.email ? userInfo.email : '-' }}
            </p>
        </div>
        <div class="items-menu">
          <div class="items-menu-base">
            <!-- <div class="items-menu-base-block">
              <router-link tag="div" :to="{ name: 'home' }">
                <div class="description" @click="isVisibleMobileMenu = false">
                  <svg class="menu_svg" width="20" height="18" color="white">
                    <use href="#menu_home"></use>
                  </svg>
                  <span>Главная</span>
                </div>
              </router-link>
            </div> -->

            <div
              class="items-menu-base-block"
              :class="{
                'router-link-exact-active': activeRouteSection == 'admin',
              }"
              v-if="userCan('admin') || userCan('superadmin')"
            >
              <!-- <div class="description" @click="changeMenu('admin')">
                <svg class="menu_svg" width="21" height="20" color="white">
                  <use href="#menu_administr"></use>
                </svg>
                <span>Администрирование</span>
                <svg
                  class="menu_svg menu_svg-arrow"
                  width="10"
                  height="5"
                  color="white"
                  :style="{
                    transform: activeRouteSection === 'admin' ? 'rotate(0)' : 'rotate(180deg)'
                  }"
                >
                  <use href="#menu_arrow"></use>
                </svg>
              </div> -->
              <div v-if="activeRouteSection === 'admin'" class="links links--administr">
                <!-- <router-link
                  :to="{ name: 'roles' }"
                  v-if="userCan('role_read_all')"
                  class="links-item"
                >
                <p @click="isVisibleMobileMenu = false">
                  Роли
                </p>
                </router-link> -->
                <router-link
                  :to="{ name: 'organisations' }"
                  v-if="userCan('organisation_read_all')"
                  class="links-item"
                >
                  <p @click="isVisibleMobileMenu = false">
                    Организации
                  </p>
                </router-link>
                <router-link
                  :to="{ name: 'mobile-user_all' }"
                  v-if="userCan('user_read_all')"
                  class="links-item"
                >
                  <p @click="isVisibleMobileMenu = false">
                    Пользователи
                  </p>
                </router-link>
              </div>
            </div>

            <div
              class="items-menu-base-block"
              :class="{
                'router-link-exact-active': activeRouteSection == 'reference',
              }"
              v-if="
                userCan('container_type_read_all') ||
                userCan('container_platform_type_read_all') ||
                userCan('failure_reason_read_all') ||
                userCan('breakdowns_type_read_all') ||
                userCan('payment_category_name_read_all')
              "
            >
              <div class="description" @click="changeMenu('reference')">
                <span>Справочники</span>
                <img
                  class="menu_svg menu_svg-arrow" 
                  width="10"
                  height="5"
                  src="@/assets/icons/mobile_menu/arrow-menu.svg"
                  :style="{
                    transform: activeRouteSection === 'reference' ? 'rotate(0)' : 'rotate(180deg)'
                  }"
                  >
              </div>
              <div v-if="activeRouteSection === 'reference'" class="links links--bookmark">
                <router-link
                  :to="{ name: 'role_all' }"
                  v-if="userCan('role_read_all')"
                  class="links-item"
                >
                  <p @click="isVisibleMobileMenu = false">
                    Роли
                  </p>
                </router-link>
                <router-link
                  :to="{ name: 'organisations' }"
                  v-if="userCan('organisation_read_all')" 
                  class="links-item"
                >
                  <p @click="isVisibleMobileMenu = false">
                    Организации
                  </p>
                </router-link>
                <router-link
                  :to="{ name: 'mobile-user_all' }"
                  v-if="userCan('user_read_all')"
                  class="links-item"
                >
                  <p @click="isVisibleMobileMenu = false">
                    Пользователи
                  </p>
                </router-link>
                <router-link
                  :to="{ name: 'container_type' }"
                  v-if="userCan('container_type_read_all')"
                  class="links-item"
                >
                  <p @click="isVisibleMobileMenu = false">
                    Типы контейнеров
                  </p>
                </router-link>
                <router-link :to="{ name: 'garbage_type' }" class="links-item">
                  <p @click="isVisibleMobileMenu = false">
                    Типы Отходов
                  </p>
                </router-link>
                <router-link
                  :to="{ name: 'platform_type' }"
                  v-if="userCan('container_platform_type_read_all')"
                  class="links-item"
                >
                <p @click="isVisibleMobileMenu = false">
                  Типы КП
                </p> 
                </router-link>
                <router-link
                  :to="{ name: 'failure_reason' }"
                  v-if="userCan('failure_reason_read_all')"
                  class="links-item"
                >
                  <p @click="isVisibleMobileMenu = false">
                    Причины невывоза
                  </p>
                </router-link>
                <router-link
                  :to="{ name: 'early completion' }"
                  v-if="userCan('work_order_cancelation_reason_read_all')"
                  class="links-item"
                >
                  <p @click="isVisibleMobileMenu = false">
                    Причины досрочного<br />завершения рейса
                  </p>
                </router-link>
                <router-link
                  :to="{ name: 'breakdown_type' }"
                  v-if="userCan('breakdowns_type_read_all')"
                  class="links-item"
                >
                  <p @click="isVisibleMobileMenu = false">
                    Виды поломок
                  </p>
                </router-link>
                <router-link
                  :to="{ name: 'weight_cancel_reason' }"
                  class="links-item"
                >
                  <p @click="isVisibleMobileMenu = false">
                    Причины отмены<br />взвешиваний
                  </p>
                </router-link>
                <router-link :to="{ name: 'list_sensor_containers' }" class="links-item">
                Контейнеры с датчиками
              </router-link>
              <router-link :to="{ name: 'reasons_stopped_container' }" class="links-item">
                Причины приостановки контейнеров
              </router-link>
              </div>
            </div>

            <div
              class="items-menu-base-block"
              :class="{
                'router-link-exact-active': activeRouteSection == 'inventory',
              }"
              v-if="
                userCan('container_platform_card_read_all') ||
                userCan('container_card_read_all')
              "
            >
              <div class="description" @click="changeMenu('inventory')">
                <span>Инвентаризация</span>
                <img
                  class="menu_svg menu_svg-arrow" 
                  width="10"
                  height="5"
                  src="@/assets/icons/mobile_menu/arrow-menu.svg"
                  :style="{
                    transform: activeRouteSection === 'inventory' ? 'rotate(0)' : 'rotate(180deg)'
                  }"
                >
              </div>
              <div v-if="activeRouteSection === 'inventory'" class="links links--inventory">
                <router-link
                  :to="{ name: 'inventory' }"
                  v-if="userCan('container_platform_card_read_all')"
                  class="links-item"
                >
                  <p @click="isVisibleMobileMenu = false">
                    На карте
                  </p>
                </router-link>
                <router-link
                  :to="{ name: 'inventory_platform' }"
                  v-if="userCan('container_platform_card_read_all')"
                  class="links-item"
                >
                  <p @click="isVisibleMobileMenu = false">
                    Контейнерные площадки
                  </p>
                </router-link>
              </div>
            </div>

            <!-- <div
              class="items-menu-base-block"
              :class="{
                'router-link-exact-active': activeRouteSection == 'carpark',
              }"
              v-if="userCan('vehicle_read_all')"
            >
              <div class="description" @click="changeMenu('carpark')">
                <svg class="menu_svg" width="20" height="15" color="white">
                  <use href="#menu_car"></use>
                </svg>
                <span>Автопарк</span>
                <svg
                  class="menu_svg menu_svg-arrow"
                  width="10"
                  height="5"
                  color="white"
                  :style="{
                    transform: activeRouteSection === 'carpark' ? 'rotate(0)' : 'rotate(180deg)'
                  }"
                >
                  <use href="#menu_arrow"></use>
                </svg>
              </div>
              <div v-if="activeRouteSection === 'carpark'" class="links links--car">
                <router-link
                  :to="{ name: 'mobile_vehicle' }"
                  v-if="userCan('vehicle_read_all') || back_office"
                  class="links-item"
                >
                  <p @click="isVisibleMobileMenu = false">
                    Автомобили
                  </p>
                </router-link>
              </div>
            </div> -->

            <div
              class="items-menu-base-block"
              :class="{
                'router-link-exact-active': activeRouteSection == 'fact',
              }"
              v-if="userCan('monitoring_read')"
            >
              <div class="description" @click="changeMenu('fact')">
                <span>Факт</span>
                <img
                  class="menu_svg menu_svg-arrow" 
                  width="10"
                  height="5"
                  src="@/assets/icons/mobile_menu/arrow-menu.svg"
                  :style="{
                    transform: activeRouteSection === 'fact' ? 'rotate(0)' : 'rotate(180deg)'
                  }"
                >
              </div>
              <div v-if="activeRouteSection === 'fact'" class="links links--fact">
                <router-link
                  :to="{ name: sizeOnlineMonitoring }"
                  class="links-item"
                >
                  <p @click="isVisibleMobileMenu = false">
                    Онлайн мониторинг
                  </p>
                </router-link>
                <router-link :to="{ name: 'mobile' }" class="links-item">
                  <p @click="isVisibleMobileMenu = false">
                    Мобильный версия
                  </p>
                </router-link>
                <router-link
                  :to="{ name: 'online_monitoring_photo' }"
                  class="links-item"
                >
                  <p @click="isVisibleMobileMenu = false">
                    Фотографии
                  </p>
                </router-link>
                <router-link
                  :to="{ name: 'vehicle' }"
                  v-if="userCan('vehicle_read_all') || back_office"
                  class="links-item"
                >
                  <p @click="isVisibleMobileMenu = false">
                    Автомобили
                  </p>
                </router-link>
                <router-link :to="{ name: 'online_list' }" class="links-item">
                  Онлайн-список
                </router-link>
                <!-- <router-link :to="{ name: 'fact_norm' }" class="links-item">
                  Нормативы
                </router-link> -->
              </div>
            </div>

            <div
              class="items-menu-base-block"
              :class="{
                'router-link-exact-active': activeRouteSection == 'logistic',
              }"
              v-if="userCan('monitoring_read')"
            >
              <div class="description" @click="changeMenu('logistic')">
                <span>Логистика</span>
                <img
                  class="menu_svg menu_svg-arrow" 
                  width="10"
                  height="5"
                  src="@/assets/icons/mobile_menu/arrow-menu.svg"
                  :style="{
                    transform: activeRouteSection === 'logistic' ? 'rotate(0)' : 'rotate(180deg)'
                  }"
                >
              </div>
              <div v-if="activeRouteSection === 'logistic'" class="links">
                <router-link :to="{ name: 'polygon' }" class="links-item" style="margin-top: 0px;">
                  <p @click="isVisibleMobileMenu = false">
                    Полигоны
                  </p>
                </router-link>
                <a
                  v-for="(item, index) of urlLogistick"
                  :key="index"
                  target="_blank"
                  :href="item?.url"
                >
                  <p>
                    {{ item?.name }}
                  </p>
                </a>
              </div>
            </div>

            <div
              class="items-menu-base-block"
              :class="{
                'router-link-exact-active':
                  activeRouteSection == 'technicalDepartment',
              }"
              v-if="back_office"
            >
              <div class="description" @click="changeMenu('technicalDepartment')">
                <span>Технический&nbsp;отдел</span>
                <img
                  class="menu_svg menu_svg-arrow" 
                  width="10"
                  height="5"
                  src="@/assets/icons/mobile_menu/arrow-menu.svg"
                  :style="{
                    transform: activeRouteSection === 'technicalDepartment' ? 'rotate(0)' : 'rotate(180deg)'
                  }"
                >
              </div>
              <div v-if="activeRouteSection === 'technicalDepartment'" class="links links--integry">
                <router-link :to="{ name: 'vehicle_sensor' }" class="links-item">
                  <p @click="isVisibleMobileMenu = false">
                    Все Gserver'a
                  </p>
                </router-link>
                <router-link
                  :to="{ name: 'all_list_sensor_components' }"
                  class="links-item"
                >
                  <p @click="isVisibleMobileMenu = false">
                    Список всех компонентов
                  </p>
                </router-link>
              </div>
            </div>

            <div
              class="items-menu-base-block"
              :class="{
                'router-link-exact-active': activeRouteSection == 'Integration',
              }"
              v-if="userCan('superadmin') || userCan('admin')"
            >
              <div class="description" @click="changeMenu('Integration')">
                <span>Интеграция</span>
                <img
                  class="menu_svg menu_svg-arrow" 
                  width="10"
                  height="5"
                  src="@/assets/icons/mobile_menu/arrow-menu.svg"
                  :style="{
                    transform: activeRouteSection === 'Integration' ? 'rotate(0)' : 'rotate(180deg)'
                  }"
                >
              </div>
              <div v-if="activeRouteSection === 'Integration'"  class="links links--integry">
                <router-link :to="{ name: 'Integration' }" class="links-item">
                  <p @click="isVisibleMobileMenu = false">
                    РГСОФТ
                  </p>
                </router-link>
                <router-link :to="{ name: 'Integrations' }" v-if="userCan('superadmin')" class="links-item">
                  <p @click="isVisibleMobileMenu = false">
                    Автоспецбаза
                  </p>
                </router-link>
              </div>
            </div>
          </div>
        </div>

        <footer class="restyle-menu__mobile-footer">
          <p @click="logout" class="restyle-menu__mobile-footer__text">Выйти</p>
          <img @click="logout" class="restyle-menu__mobile-footer__img" src="@/assets/icons/mobile_menu/logout.svg" alt="logout">
        </footer>
      </div>
    </div>
    <div v-if="!isVisibleMobileMenu && !isVisibleUserCreate && !isVisibleUserEdit && !isVisibleVehicleCreate && !isVisibleVehicleEdit && !isVisibleVehicleDetailPage && !isVisibleInstallationKitBinding && !isVisibleComponentInstKit" class="area-close-menu">
      <button class="area-close-menu__btn" @click="isVisibleMobileMenu = true">
        <img class="area-close-menu__logo" src="@/assets/icons/mobile_menu/hamburger.svg" alt="logo" />
      </button>
      <!-- <transition name="fade"> -->
        <h3
          v-if="isActiveScroll"
          class="area-close-menu__title"
          >
          {{ $route.meta.title }}
        </h3>
      <!-- </transition> -->
      <img class="area-close-menu__logo" src="@/assets/icons/mobile_menu/sr_logo_black.svg" alt="logo" />
    </div>
    <div v-if="isVisibleUserCreate || isVisibleUserEdit || isVisibleVehicleCreate || isVisibleVehicleEdit || isVisibleVehicleDetailPage || isVisibleInstallationKitBinding || isVisibleComponentInstKit" class="area-close-menu">
      <button class="area-close-menu__btn" @click="toBackBTN()">
        <img class="area-close-menu__logo" src="@/assets/icons/mobile_menu/arrow_back_blue.svg" alt="logo" />
      </button>
        <h3
          class="area-close-menu__title"
          >
          {{ topHeaderText }}
        </h3>
      <img class="area-close-menu__logo" src="@/assets/icons/mobile_menu/sr_logo_black.svg" alt="logo" />
    </div>
    <!-- <div v-if="isVisibleVehicleDetailPage" class="area-close-menu">
      <button class="area-close-menu__btn" @click="toBackBTN()">
        <img class="area-close-menu__logo" src="@/assets/icons/mobile_menu/arrow_back_blue.svg" alt="logo" />
      </button>
        <h3
          class="area-close-menu__title"
          >
          Об автомобиле
        </h3>
      <img class="area-close-menu__logo" src="@/assets/icons/mobile_menu/sr_logo_black.svg" alt="logo" />
    </div> -->
  </div>
</template>

<script>
import { mapGetters, mapMutations } from "vuex";

export default {
  components: {
  },
  data() {
    return {
      isVisibleMobileMenu: false,
      activeItem: null,
      isAtiveCursorUrlLogistick: false,
      activeRouteSection: '',
    };
  },
  methods: {
    ...mapMutations({
      setIsVisibleUserCreate: "mobile_menu/SET_IS_VISIBLE_USER_CREATE",
      setIsVisibleUserEdit: "mobile_menu/SET_IS_VISIBLE_USER_EDIT",
      setIsChangeUserShowModal: "mobile_menu/SET_IS_CHANGE_USER_SHOW_MODAL",
      setIsVisibleVehicleCreate: "mobile_menu/SET_IS_VISIBLE_VEHICLE_CREATE",
      setIsVisibleVehicleEdit: "mobile_menu/SET_IS_VISIBLE_VEHICLE_EDIT",
      setIsVisibleVehicleDetailPage: "mobile_menu/SET_IS_VISIBLE_VEHICLE_DETAIL_PAGE",
      setIsVisibleInstallation: "mobile_menu/SET_IS_VISIBLE_INSTALLATION",
      setIsVisibleComponentInstKit: "mobile_menu/SET_IS_VISIBLE_COMPONENT_INST_KIT"
    }),
    changeMenu(nameMenu) {
      if (this.activeRouteSection !== nameMenu) {
        this.activeRouteSection = nameMenu;
      } else {
          this.activeRouteSection = ''
      }
    },
    logout() {
      this.$store.dispatch("auth/logout");
      this.$router.push({ name: "login" });
    },
    toBackBTN() {
      if (this.getIsChangeUserForm) {
        this.setIsChangeUserShowModal(true);
      } else {
        this.setIsVisibleUserCreate(false);
        this.setIsVisibleUserEdit(false);
        this.setIsVisibleVehicleCreate(false);
        this.setIsVisibleVehicleEdit(false);
        this.setIsVisibleVehicleDetailPage(false);
        this.setIsVisibleInstallation(false);
        this.setIsVisibleComponentInstKit(false);
      }
    },
  },
  computed: {
    ...mapGetters({
      userCan: "user/userCan",
      userInfo: "user/user",
      sizeOnlineMonitoring: "navigation/sizeOnlineMonitoring",
      organisations: "user/userOrganisations",
      isActiveScroll: "mobile_menu/GET_IS_ACTIVE_SCROLL",
      isVisibleUserCreate: "mobile_menu/GET_IS_VISIBLE_USER_CREATE",
      isVisibleUserEdit: "mobile_menu/GET_IS_VISIBLE_USER_EDIT",
      isVisibleVehicleCreate: "mobile_menu/GET_IS_VISIBLE_VEHICLE_CREATE",
      isVisibleVehicleEdit: "mobile_menu/GET_IS_VISIBLE_VEHICLE_EDIT",
      getIsChangeUserForm: "mobile_menu/GET_IS_CHANGE_USER_FORM",
      isVisibleVehicleDetailPage: "mobile_menu/GET_IS_VISIBLE_VEHICLE_DETAIL_PAGE",
      isVisibleInstallationKitBinding: "mobile_menu/GET_IS_VISIBLE_INSTALLATION",
      isVisibleComponentInstKit: "mobile_menu/GET_IS_VISIBLE_COMPONENT_INST_KIT"
    }),
    topHeaderText() {
      if (this.isVisibleVehicleDetailPage) {
        return "Об автомобиле";
      } else if (this.isVisibleUserCreate || this.isVisibleVehicleCreate) {
        return "Создать";
      } else if (this.isVisibleInstallationKitBinding) {
        return "Привязать комплект";
      } else if (this.isVisibleComponentInstKit) {
        return "Комплект установки"
      } else {
        return "Редактировать"
      }
    },
    urlLogistick() {
      let result = [];
      if (this.organisations.length > 0) {
        this.organisations.forEach((val) => {
          if (val.hostname) {
            result.push({
              name: val.name,
              url: new URL(`https://${val.hostname}`),
            });
          }
        });
      }

      return result;
    },
    back_office() {
      return this.userCan('technical_department_root') || Boolean(process.env.VUE_APP_LOCAL_DEV);
    }
  },
};
</script>

<style lang="scss" scoped>
$delay-open-menu: 0.5s;
$width_full: 100vw;

.restyle-menu__mobile {
  &-wrapper {
    position: fixed;
    left: 0;
    top: 0;
    height: 50px;
    background-color: white;
  }

  z-index: 1;
  position: relative;
  width: $width_full;
  height: 100vh;
  background: linear-gradient(180deg, #1d9c95 0%, #1d106f 100%);
  transition: transform $delay-open-menu ease;
  transform: translateX(-100%);

  &--active {
    transform: translateX(0%);
  }

  $this: &;

  &-footer {
    display: flex;
    align-items: center;
    color: white;
    padding: 0 28px;
    cursor: pointer;
    margin-bottom: 30px;
    &__img {

    }
    &__text {
      margin-right: 8px;
      margin-bottom: 0;
    }
  }

  .items-menu {
    margin-top: 30px;
    margin-bottom: 30px;
    overflow-y: auto;
    &::-webkit-scrollbar {
        display: none;
    }
  }
}
.block-menu {
  padding-top: 40px;
  display: flex;
  flex-direction: column;
  height: 100%;
  max-height: 100vh;
  overflow: hidden;
  
  &__top-items {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 28px;
    &__left {

    }
    &__right {

    }
  }

  &__user-info {
    padding: 0 28px;
    margin-top: 30px;
    color: white;
    display: flex;
    align-items: center;
    &__img {
      margin-right: 10px;
    }
    &__text {
      margin-bottom: 0;
    }
  }
}

.menu_svg {
  width: 20px;
  flex: 0 0 auto;
}

.items-menu-base-block {
  position: relative;
  cursor: pointer;

    & .links {
      transition: all 4s ease;
      max-height: 1000px;
    }
}

.router-link-exact-active {
  .description * {
    color: #44ff8f;
  }
}
.description {
  padding: 20px 0 20px 28px;
  display: flex;
  align-items: center;

  & img {
    vertical-align: sub;
  }

  & span {
    margin-right: 10px;
    margin-left: 12px;
    font-weight: 400;
    font-size: 18px;
    line-height: 25px;
    color: #ffffff;
  }
}

.links {
  display: grid;
  grid-template-columns: 1fr;
  grid-row-gap: 10px;
  max-height: 0;
  transition-delay: 0s;
  transition-property: all;
  transition-duration: 0.4s;
  transition-timing-function: ease;
  overflow: hidden;

  & a {
    position: relative;
    padding-left: 61px;
    font-weight: 400;
    font-size: 16px;
    line-height: 22px;
    color: #ffffff;

    &:nth-last-child(-n + 1) {
      margin-bottom: 15px;
    }
  }
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.6s;
}
.fade-enter,
.fade-leave-to {
  opacity: 0;
}

.area-close-menu {
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 20px;
  position: absolute;
  top: 0;
  left: 0;
  width: 100vw;
    &__logo {

    }
    &__btn {
      cursor: pointer;
    }
    &__title {
      color: rgb(29, 24, 113);
      font-size: 22px;
      margin-bottom: 0;
    }
}

.links-item {
  font-weight: 100 !important;
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.6s;
}
.fade-enter,
.fade-leave-to {
  opacity: 0;
}

@media (max-width: 576px) {
   .restyle-menu__mobile {
      display: block;
   }
   .area-close-menu {
    display: flex;
   }
}
@media (min-width: 577px) {
   .restyle-menu__mobile {
      display: none;
   }
  .area-close-menu {
    display: none;
   }
}
</style>