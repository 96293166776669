import { request } from "../../_helpers"

export const rework_online_monitoring_list_work_orders = {
  namespaced: true,
  state: {
    listWorkOrders: [],
    listSummaryWorkOrders: [],
    activeWorkOrder: null,
    activeContainerPlatform: null,
    viewAllWorkOrder: false,
    viewPlatformsOnMap: [],
    detailInfoContainerPlatform: null,
  },
  getters: {
    getListWorkOrders: state => state.listWorkOrders,
    getListSummaryWorkOrders: state => state.listSummaryWorkOrders,
    getActiveWorkOrder: state => state.activeWorkOrder,
    getActiveContainerPlatform: state => state.activeContainerPlatform,
    getDetailInfoContainerPlatform: state => state.detailInfoContainerPlatform,
    getViewAllWorkOrder: state => state.viewAllWorkOrder,
    getViewPlatformsOnMap: state => state.viewPlatformsOnMap,
  },
  actions: {
    setListWorkOrders({commit}, payload) {
      commit('setListWorkOrders', payload);
    },
    setListSummaryWorkOrders({commit}, payload) {
      commit('setListSummaryWorkOrders', payload);
    },
    setActiveContainerPlatform({commit}, payload) {
      commit('setActiveContainerPlatform', payload);
      if (payload) {
        request(
          `${process.env.VUE_APP_WN}/fact/platform/${payload.id}`,
          "get"
        ).then((res) => commit('setDetailInfoContainerPlatform', res.success ? res.data : null))
      } else {
        commit('setDetailInfoContainerPlatform', null)
      }
    },
  },
  mutations: {
    setListWorkOrders(state, payload) {
      state.listWorkOrders = payload
    },
    setListSummaryWorkOrders(state, payload) {
      state.listSummaryWorkOrders = payload
    },
    setActiveWorkOrder(state, payload) {
      state.activeWorkOrder = payload
    },
    setActiveContainerPlatform(state, payload) {
      state.activeContainerPlatform = payload
    },
    setDetailInfoContainerPlatform(state, payload) {
      state.detailInfoContainerPlatform = payload
    },
    toggleViewAllWorkOrder(state) {
      state.viewAllWorkOrder = !state.viewAllWorkOrder
    },
    toggleViewPlatformsOnMap(state, payload) {
      state.viewPlatformsOnMap = state.viewPlatformsOnMap.includes(payload)? state.viewPlatformsOnMap.filter(e => e != payload): [...state.viewPlatformsOnMap, payload];
    },
    clearActiveStateToDefaultValue(state) {
      state.activeWorkOrder = null;
      state.activeContainerPlatform = null;
      state.viewAllWorkOrder = false;
      state.viewPlatformsOnMap = [];
      state.detailInfoContainerPlatform = null;
    },
  }
}