<template>
  <div class="reference-block">
    <div
      id="reference-role"
      class="reference-block-link"
      v-if="
        userCan('role_read_all') && (userCan('admin') || userCan('superadmin'))
      "
    >
      <router-link :to="{ name: 'role_all' }" class="links-item">      
        <div class="link-flex">
            {{ getByHotSpotRouteTitleMenu('role_all') }}
          <div v-if="isActiveHotSpot('role_all')?.id" class="hotspot"
          @mouseenter="$store.dispatch('tooltip_store/SET_VIEW_TOOLTIP', {
              hoverEvent: $event,
              tooltipText: currentTooltipForHotSpot('role_all'),
            })" 
          @mouseleave="$store.dispatch('tooltip_store/CLEAR_VIEW_TOOLTIP');">
          Новое
          </div>
        </div>
      </router-link>
    </div>
    <div
      id="reference-organisation"
      class="reference-block-link"
      v-if="
        userCan('organisation_read_all') &&
        (userCan('admin') || userCan('superadmin'))
      "
    >
      <router-link :to="{ name: 'organisations' }" class="links-item">
        <div class="link-flex">
          {{ getByHotSpotRouteTitleMenu('organisations') }}
          <div v-if="isActiveHotSpot('organisations')?.id" class="hotspot"
          @mouseenter="$store.dispatch('tooltip_store/SET_VIEW_TOOLTIP', {
              hoverEvent: $event,
              tooltipText: currentTooltipForHotSpot('organisations'),
            })" 
          @mouseleave="$store.dispatch('tooltip_store/CLEAR_VIEW_TOOLTIP');">
          Новое
          </div>
        </div>
      </router-link>
    </div>
    <div
      id="reference-users"
      class="reference-block-link"
      v-if="
        userCan('user_read_all') && (userCan('admin') || userCan('superadmin'))
      "
    >
      <router-link :to="{ name: 'user_all' }" class="links-item">
        <div class="link-flex">
          {{ getByHotSpotRouteTitleMenu('user_all') }}
          <div v-if="isActiveHotSpot('user_all')?.id" class="hotspot"
          @mouseenter="$store.dispatch('tooltip_store/SET_VIEW_TOOLTIP', {
              hoverEvent: $event,
              tooltipText: currentTooltipForHotSpot('user_all'),
            })" 
          @mouseleave="$store.dispatch('tooltip_store/CLEAR_VIEW_TOOLTIP');">
          Новое
          </div>
        </div>
      </router-link>
    </div>
    <div id="reference-container_type" class="reference-block-link" v-if="userCan('container_type_read_all')">
      <router-link :to="{ name: 'container_type' }" class="links-item">
        <div class="link-flex">
          {{ getByHotSpotRouteTitleMenu('container_type') }}
          <div v-if="isActiveHotSpot('container_type')?.id" class="hotspot"
          @mouseenter="$store.dispatch('tooltip_store/SET_VIEW_TOOLTIP', {
              hoverEvent: $event,
              tooltipText: currentTooltipForHotSpot('container_type'),
            })" 
          @mouseleave="$store.dispatch('tooltip_store/CLEAR_VIEW_TOOLTIP');">
          Новое
          </div>
        </div>
      </router-link>
    </div>
    <div
      id="reference-waste_type"
      class="reference-block-link"
      v-if="
        userCan('container_type_read_all') ||
        userCan('container_platform_type_read_all') ||
        userCan('failure_reason_read_all') ||
        userCan('breakdowns_type_read_all') ||
        userCan('payment_category_name_read_all')
      "
    >
      <router-link :to="{ name: 'garbage_type' }" class="links-item">
        <div class="link-flex">
          {{ getByHotSpotRouteTitleMenu('garbage_type') }}
          <div v-if="isActiveHotSpot('garbage_type')?.id" class="hotspot"
          @mouseenter="$store.dispatch('tooltip_store/SET_VIEW_TOOLTIP', {
              hoverEvent: $event,
              tooltipText: currentTooltipForHotSpot('garbage_type'),
            })" 
          @mouseleave="$store.dispatch('tooltip_store/CLEAR_VIEW_TOOLTIP');">
          Новое
          </div>
        </div>
      </router-link>
    </div>
    <div
      id="reference-type_platforms"
      class="reference-block-link"
      v-if="userCan('container_platform_type_read_all')"
    >
      <router-link :to="{ name: 'platform_type' }" class="links-item">
        <div class="link-flex">
          {{ getByHotSpotRouteTitleMenu('platform_type') }}
          <div v-if="isActiveHotSpot('platform_type')?.id" class="hotspot"
          @mouseenter="$store.dispatch('tooltip_store/SET_VIEW_TOOLTIP', {
              hoverEvent: $event,
              tooltipText: currentTooltipForHotSpot('platform_type'),
            })" 
          @mouseleave="$store.dispatch('tooltip_store/CLEAR_VIEW_TOOLTIP');">
          Новое
          </div>
        </div>
      </router-link>
    </div>
    <div id="reference-failure_reason" class="reference-block-link" v-if="userCan('failure_reason_read_all')">
      <router-link :to="{ name: 'failure_reason' }" class="links-item">
        <div class="link-flex">
          {{ getByHotSpotRouteTitleMenu('failure_reason') }}
          <div v-if="isActiveHotSpot('failure_reason')?.id" class="hotspot"
          @mouseenter="$store.dispatch('tooltip_store/SET_VIEW_TOOLTIP', {
              hoverEvent: $event,
              tooltipText: currentTooltipForHotSpot('failure_reason'),
            })" 
          @mouseleave="$store.dispatch('tooltip_store/CLEAR_VIEW_TOOLTIP');">
          Новое
          </div>
        </div>
      </router-link>
    </div>
    <div
      id="reference-cancelation_reason"
      class="reference-block-link"
      v-if="userCan('work_order_cancelation_reason_read_all')"
    >
      <router-link :to="{ name: 'early completion' }" class="links-item">
        <div class="link-flex">
          {{ getByHotSpotRouteTitleMenu('early completion') }}
          <div v-if="isActiveHotSpot('early completion')?.id" class="hotspot"
          @mouseenter="$store.dispatch('tooltip_store/SET_VIEW_TOOLTIP', {
              hoverEvent: $event,
              tooltipText: currentTooltipForHotSpot('early completion'),
            })" 
          @mouseleave="$store.dispatch('tooltip_store/CLEAR_VIEW_TOOLTIP');">
          Новое
          </div>
        </div>
      </router-link>
    </div>
    <div
      id="reference-breakdowns_type"
      class="reference-block-link"
      v-if="userCan('breakdowns_type_read_all')"
    >
      <router-link :to="{ name: 'breakdown_type' }" class="links-item">
        <div class="link-flex">
          {{ getByHotSpotRouteTitleMenu('breakdown_type') }}
          <div v-if="isActiveHotSpot('breakdown_type')?.id" class="hotspot"
          @mouseenter="$store.dispatch('tooltip_store/SET_VIEW_TOOLTIP', {
              hoverEvent: $event,
              tooltipText: currentTooltipForHotSpot('breakdown_type'),
            })" 
          @mouseleave="$store.dispatch('tooltip_store/CLEAR_VIEW_TOOLTIP');">
          Новое
          </div>
        </div>
      </router-link>
    </div>
    <div
      id="reference-cancel_reason"
      class="reference-block-link"
      v-if="
        userCan('container_type_read_all') ||
        userCan('container_platform_type_read_all') ||
        userCan('failure_reason_read_all') ||
        userCan('breakdowns_type_read_all') ||
        userCan('payment_category_name_read_all')
      "
    >
      <router-link :to="{ name: 'weight_cancel_reason' }" class="links-item">
        <div class="link-flex">
          {{ getByHotSpotRouteTitleMenu('weight_cancel_reason') }}
          <div v-if="isActiveHotSpot('weight_cancel_reason')?.id" class="hotspot"
          @mouseenter="$store.dispatch('tooltip_store/SET_VIEW_TOOLTIP', {
              hoverEvent: $event,
              tooltipText: currentTooltipForHotSpot('weight_cancel_reason'),
            })" 
          @mouseleave="$store.dispatch('tooltip_store/CLEAR_VIEW_TOOLTIP');">
          Новое
          </div>
        </div>
      </router-link>
    </div>
    <div
      id="reference-list_sensor_containers"
      class="reference-block-link"
      v-if="userCan('superadmin') || userCan('admin')"
    >
      <router-link :to="{ name: 'list_sensor_containers' }" class="links-item">
        <div class="link-flex">
          {{ getByHotSpotRouteTitleMenu('list_sensor_containers') }}
          <div v-if="isActiveHotSpot('list_sensor_containers')?.id" class="hotspot"
          @mouseenter="$store.dispatch('tooltip_store/SET_VIEW_TOOLTIP', {
              hoverEvent: $event,
              tooltipText: currentTooltipForHotSpot('list_sensor_containers'),
            })" 
          @mouseleave="$store.dispatch('tooltip_store/CLEAR_VIEW_TOOLTIP');">
          Новое
          </div>
        </div>
      </router-link>
    </div>
    <div
      id="reference-reasons_stopped_container"
      class="reference-block-link"
      v-if="userCan('superadmin') || userCan('admin') || userCan('role_read_all')"
    >
      <router-link :to="{ name: 'reasons_stopped_container' }" class="links-item">
        <div class="link-flex">
          {{ getByHotSpotRouteTitleMenu('reasons_stopped_container') }}
          <div v-if="isActiveHotSpot('reasons_stopped_container')?.id" class="hotspot"
          @mouseenter="$store.dispatch('tooltip_store/SET_VIEW_TOOLTIP', {
              hoverEvent: $event,
              tooltipText: currentTooltipForHotSpot('reasons_stopped_container'),
            })" 
          @mouseleave="$store.dispatch('tooltip_store/CLEAR_VIEW_TOOLTIP');">
          Новое
          </div>
        </div>
      </router-link>
    </div>
    <div
      id="reference-reasons_stopped_container"
      class="reference-block-link"
      v-if="userCan('superadmin') || userCan('claim_reasons_show')"
    >
      <router-link :to="{ name: 'reasons_for_the_application' }" class="links-item">
        <div class="link-flex">
          {{ getByHotSpotRouteTitleMenu('reasons_for_the_application') }}
          <div v-if="isActiveHotSpot('reasons_for_the_application')?.id" class="hotspot"
          @mouseenter="$store.dispatch('tooltip_store/SET_VIEW_TOOLTIP', {
              hoverEvent: $event,
              tooltipText: currentTooltipForHotSpot('reasons_for_the_application'),
            })" 
          @mouseleave="$store.dispatch('tooltip_store/CLEAR_VIEW_TOOLTIP');">
          Новое
          </div>
        </div>
      </router-link>
    </div>
    <div
      id="reference-navigation_systems"
      class="reference-block-link"
      v-if="userCan('monitoring_systems_show')"
    >
      <router-link :to="{ name: 'navigation_systems' }" class="links-item">
        <div class="link-flex">
          {{ getByHotSpotRouteTitleMenu('navigation_systems') }}
          <div v-if="isActiveHotSpot('navigation_systems')?.id" class="hotspot"
          @mouseenter="$store.dispatch('tooltip_store/SET_VIEW_TOOLTIP', {
              hoverEvent: $event,
              tooltipText: currentTooltipForHotSpot('navigation_systems'),
            })" 
          @mouseleave="$store.dispatch('tooltip_store/CLEAR_VIEW_TOOLTIP');">
          Новое
          </div>
        </div>
      </router-link>
    </div>
    <div
      id="reference-divisions_organisation"
      v-if="userCan('departments_show') || userCan('departments_manage')"
      class="reference-block-link"
    >
      <router-link :to="{ name: 'divisions_organisation' }" class="links-item">
        <div class="link-flex">
          {{ getByHotSpotRouteTitleMenu('divisions_organisation') }}
          <div v-if="isActiveHotSpot('divisions_organisation')?.id" class="hotspot"
          @mouseenter="$store.dispatch('tooltip_store/SET_VIEW_TOOLTIP', {
              hoverEvent: $event,
              tooltipText: currentTooltipForHotSpot('divisions_organisation'),
            })" 
          @mouseleave="$store.dispatch('tooltip_store/CLEAR_VIEW_TOOLTIP');">
          Новое
          </div>
        </div>
      </router-link>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  computed: {
    ...mapGetters({
      userCan: "user/userCan",
      getByHotSpotRouteTitleMenu: "hotspots/getByHotSpotRouteTitleMenu",
      allListActiveHotSpots: "hotspots/allListActiveHotSpots",
    }),
  },
  methods: {
    isActiveHotSpot(menu) {
      const rusNameMenu = this.getByHotSpotRouteTitleMenu(menu)
      const result = this.allListActiveHotSpots.find(item => item.menu === rusNameMenu);
      return result;
    },
    currentTooltipForHotSpot(menu) {
      const hotSpot = this.isActiveHotSpot(menu);
      if (Object.entries(hotSpot).length &&  hotSpot.tooltip) {
        return hotSpot.tooltip
      } 
      return ''
    }
  }
};
</script>

<style lang="scss" scoped>
.reference-block {
  padding: 20px 25px;
  z-index: 1;
  display: grid;
  row-gap: 20px;
  position: absolute;
  top: 50px;
  left: 0;
  width: 398px;
  border-radius: 5px;

  &::after {
    margin-top: 2px;
    z-index: -1;
    position: absolute;
    top: 0;
    left: 0;
    content: '';
    width: 398px;
    height: calc(100% - 2px);
    background: #1d838d;
    box-shadow: 4px 4px 8px 2px rgba(57, 134, 144, 0.5);
    border-radius: 5px;
  }

  &-link {
    font-weight: 400;
    font-size: 16px;
    line-height: 22px;
    color: #ffffff;
    height: 100%;

    & a {
      display: inline-block;
      width: 100%;
      height: 100%;
      font-weight: 400;
      font-size: 16px;
      line-height: 22px;
      color: #ffffff;
    }

    &:hover {
      & a {
        color: #44ff8f;
      }
    }
  }
  .link-flex {
    display: flex;
    align-items: center
  }
}
</style>