export const hoverStateTopMenu = {
  namespaced: true,
  state: {
    isHoverActive: false,
  },
  getters: {
    getIsHoverActive: (state) => {
      return state.isHoverActive;
    },
  },
  mutations: {
    setIsHoverActive(store, payload) {
      store.isHoverActive = payload;
    },
  },
};
