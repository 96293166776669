import { render, staticRenderFns } from "./tech.vue?vue&type=template&id=3b8428b6&scoped=true"
import script from "./tech.vue?vue&type=script&lang=js"
export * from "./tech.vue?vue&type=script&lang=js"
import style0 from "./tech.vue?vue&type=style&index=0&id=3b8428b6&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3b8428b6",
  null
  
)

export default component.exports