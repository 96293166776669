<template>
  <RestyleModal
    @cancel="$emit('close')"
    @close="$emit('close')"
    @save="sendChangePassword"
    :sendDisabled="send"
  >
    <template slot="header">
      Сменить пароль
    </template>
    <div class="header-text">
      Длина пароля должна быть
      <span style="font-weight: bold;">не менее 4 символов</span><br/>
      Пароль должен содержать <span style="font-weight: bold;">хотя бы одну строчную, прописную букву и цифру</span>
    </div>
    <div  class="password-field-user-wrapper">
      <RestyleInput
         :type="showPassword_one ? 'text' : 'password'"
         :inputText="newPassword"
         :maxlength="30"
         :required="send && !newPassword"
         @inputChange="newPassword = $event"
         style="margin-bottom: 25px;"
         >
         <template slot="title">
            Новый пароль
         </template>
         <template slot="required">
            Это поле обязательно к заполнению
         </template>
         </RestyleInput>
         <button v-if="newPassword.length" class="btn-eye" @click="showPassword_one = !showPassword_one">
            <i :class="showPassword_one ? 'mdi mdi-eye-off' : 'mdi mdi-eye'"></i>
         </button>
    </div>
    <div class="password-field-user-wrapper">
         <RestyleInput
         :type="showPassword_two ? 'text' : 'password'"
         :inputText="newPasswordAgain"
         :maxlength="30"
         :required="send && !newPasswordAgain"
         @inputChange="newPasswordAgain = $event"
         >
         <template slot="title">
            Новый пароль еще раз
         </template>
         <template slot="required">
            Это поле обязательно к заполнению
         </template>
         </RestyleInput>
         <button v-if="newPasswordAgain.length" class="btn-eye" @click="showPassword_two = !showPassword_two">
               <i :class="showPassword_two ? 'mdi mdi-eye-off' : 'mdi mdi-eye'"></i>
         </button>
    </div>
      <template slot="confirmButtonText">
         Сохранить пароль
      </template>
  </RestyleModal>
</template>

<script>
  import { request } from "@/_helpers";

  export default {
    name: 'ChangePasswordModal',
    data() {
      return {
         send: false,
         showPassword_one: false,
         showPassword_two: false,
         newPassword: '',
         newPasswordAgain: '',
      }
    },
    methods: {
      sendChangePassword() {
         this.send = true;
         if (this.newPassword !== this.newPasswordAgain) {
            this.$store.dispatch('alert/alerts', {type: 'danger', message: 'Пароли должны совпадать', title: 'Ошибка!'}, {root: true});
            return
         }
         if (!this.newPassword.length) {
            return
         }
         request(`${process.env.VUE_APP_AUTH_URL}/user`, 'put', {
            password: this.newPassword,
            password_confirmation: this.newPassword
         }).then(response => {
                  if(response.success) {
                     this.$store.dispatch('alert/alerts', {type: 'success', message: 'Пароль изменён'}, {root: true});
                     this.$emit('close');
                  }
               }).finally(() => {
                  this.send = false;
               })
      },
    },
    watch: {
      newPassword(newValue) {
         if (newValue.length) {
            this.send = false;
         }
      },
      newPasswordAgain(newValue) {
         if (newValue.length) {
            this.send = false;
         }
      }
    }
  }
</script>

<style lang="scss" scoped>
.header-text {
   font-weight: 500;
   font-size: 20px;
   line-height: 40px;
   color: $color-page-text;
   margin-bottom: 30px;
}

.password-field-user-wrapper {
  display: flex;
  flex-direction: column;
  position: relative;
  width: fit-content;
      & .btn-eye {
      background: none;
      border: none;
      cursor: pointer;
      position: absolute;
      top: 60px;
      right: 40px;
      & i {
         color: #A0AEC0;
         font-size: 24px;
         &::before {
            color: #A0AEC0;
         }
      }
      }
}
</style>

<style>
.password-field-user-wrapper > .input-required-label {
   position: absolute;
   bottom: -25px !important;
   left: 0;
}
</style>