<template>
  <div class="reference-block scrollbar scrollbar--secondary">
    <div v-if="userCan('location_show')" id="logistic-polygon" class="reference-block-link">
      <router-link :to="{ name: 'polygon' }" class="links-item">
        <div class="link-flex">
            {{ getByHotSpotRouteTitleMenu('polygon') }}
          <div v-if="isActiveHotSpot('polygon')?.id" class="hotspot"
          @mouseenter="$store.dispatch('tooltip_store/SET_VIEW_TOOLTIP', {
              hoverEvent: $event,
              tooltipText: currentTooltipForHotSpot('polygon'),
            })" 
          @mouseleave="$store.dispatch('tooltip_store/CLEAR_VIEW_TOOLTIP');">
          Новое
          </div>
        </div>
      </router-link>
    </div>
    <div
      :id="`logistic-url-${1 + index}`"
      class="reference-block-link"
      v-for="(item, index) of urlLogistick"
      :key="index"
    >
      <a target="_blank" :href="item?.url">
        <div class="link-flex">
            {{ item?.name }}
          <div v-if="isActiveHotSpot(item?.name)?.id" class="hotspot"
          @mouseenter="$store.dispatch('tooltip_store/SET_VIEW_TOOLTIP', {
              hoverEvent: $event,
              tooltipText: currentTooltipForHotSpot(item?.name),
            })" 
          @mouseleave="$store.dispatch('tooltip_store/CLEAR_VIEW_TOOLTIP');">
          Новое
          </div>
        </div>
      </a>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapMutations } from "vuex";
export default {
  created() {
    const hotSpotLogistic = this.urlLogistick.map(item => {
      return {
        routeName: item.url,
        titleMenu: item.name,
        value: item.name,
        label: item.name,
      }
    })
    this.setHotSpotRoutes(hotSpotLogistic);
  },
  methods: {
    ...mapMutations({
      setHotSpotRoutes: 'hotspots/setHotSpotRoutes'
    }),
    isActiveHotSpot(menu) {
      const rusNameMenu = this.getByHotSpotRouteTitleMenu(menu);
      const result = this.allListActiveHotSpots.find(item => item.menu === rusNameMenu || item.menu === menu);
      return result;
    },
    currentTooltipForHotSpot(menu) {
      const hotSpot = this.isActiveHotSpot(menu);
      if (Object.entries(hotSpot).length &&  hotSpot.tooltip) {
        return hotSpot.tooltip
      } 
      return ''
    }
  },
  computed: {
    ...mapGetters({
      userCan: "user/userCan",
      sizeOnlineMonitoring: "navigation/sizeOnlineMonitoring",
      organisations: "user/userOrganisations",
      getByHotSpotRouteTitleMenu: "hotspots/getByHotSpotRouteTitleMenu",
      allListActiveHotSpots: "hotspots/allListActiveHotSpots",
    }),
    urlLogistick() {
      let result = [];
      if (this.organisations.length > 0) {
        this.organisations.forEach((val) => {
          if (val.hostname) {
            result.push({
              name: val.name,
              url: new URL(`https://${val.hostname}`),
            });
          }
        });
      }

      return result;
    },
  },
};
</script>

<style lang="scss" scoped>
.reference-block {
  z-index: 1;
  padding: 20px 25px;
  display: grid;
  row-gap: 20px;
  position: absolute;
  top: 50px;
  left: 0;
  overflow-x: hidden;
  overflow-y: auto;
  width: 244px;
  max-height: 566px;
  background: #1d5f84;
  box-shadow: 4px 4px 8px 2px rgba(57, 134, 144, 0.5);
  border-radius: 5px;

  &::after {
    margin-top: 2px;
    z-index: -1;
    position: absolute;
    top: 0;
    left: 0;
    content: '';
    width: 398px;
    height: calc(100% - 2px);
    background: #1d5f84;
    border-radius: 5px;
  }

  &-link {
    font-weight: 400;
    font-size: 16px;
    line-height: 22px;
    color: #ffffff;
    height: 100%;

    & a {
      display: inline-block;
      width: 100%;
      height: 100%;
      font-weight: 400;
      font-size: 16px;
      line-height: 22px;
      color: #ffffff;
    }

    &:hover {
      & a {
        color: #44ff8f;
      }
    }
  }
  .link-flex {
    display: flex;
    align-items: center
  }
}
</style>