<template>
  <div @click="onCheckboxClick()" class="checkbox-container">
    <div v-if="checkboxValue === 'no'" class="checkbox-square transparent"></div>
    <div v-if="checkboxValue === 'partial'" class="checkbox-square"></div>
    <img
      v-if="checkboxValue === 'yes'"
      src="@/assets/restyle/images/shared/icons/checkbox.svg"
      class="checkbox-clicked"
      alt=""
    >
  </div>
</template>

<script>
  export default {
    name: 'CheckboxSquareButton',
    props: {
      checkboxValue: {
        type: String,
        required: true,
        default: 'no',
      }
    },
    model: {
      prop: 'checkboxValue',
      event: 'checkboxClicked',
    },
    methods: {
      onCheckboxClick() {
        if (this.checkboxValue === 'no' || this.checkboxValue === 'partial') {
          this.$emit('checkboxClicked', 'yes');
        } else {
          this.$emit('checkboxClicked', 'no');
        }
      }
    }
  }
</script>

<style lang="scss" scoped>

  .checkbox-container {
    border: 2px solid $color-success;
    width: 22px;
    height: 22px;
    border-radius: 3px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
  }

  .checkbox-square {
    border-radius: 2px;
    background-color: $color-success;
    width: 14px;
    height: 14px;
    display: block;
  }

  .checkbox-clicked {
    width: 14px;
    height: 14px;
    display: block;
  }

  .transparent {
    visibility: hidden;
  }

</style>