export const rework_online_monitoring_helpers = {
  namespaced: true,
  state: {
    timeUpdateData: '', // Время последнего обневления данных по сменным заданиям
    reloadUpdateCount: 0, // Счётчик для автообновления данных, отслеживаем его через watch и при его изменении идёт обновление данных по сменным заданиям
  },
  getters: {
    getTimeUpdateData: state => state.timeUpdateData,
    getReloadUpdateCount: state => state.reloadUpdateCount,
  },
  actions: {
    
  },
  mutations: {
    setTimeUpdateData(state, payload) {
      state.timeUpdateData = payload
    },
    setReloadUpdateCount(state) {
      state.reloadUpdateCount += 1
    },
  }
}