import { request } from "../_helpers";

export const roles  = {
    namespaced: true,
    state: {
        roles: [],
        roles_SRP: []
    },
    getters: {
        allRoles: state => {
            return state.roles;
        },
        current: state => id =>{
            const result = state.roles.filter(role => {
                return role.id === parseInt(id)
            });
            return result ? result[0] : null;
        },
        currentRoles: state => roles => {
            let result = [];
            state.roles.forEach(role => {
                if(roles.some(id => {return role.id === id})) {
                    result.push(role);
                }
            });
            return result;
        },
        roles_SRP: state => state.roles_SRP,
        currentRolesSRP: state => roles => state.roles_SRP.filter(role => roles.indexOf(role.id) > -1 )
    },
    actions: {
        async getRolesSRP(store) {
            store.commit('setRolesSRP', await request(`${process.env.VUE_APP_AUTH_URL}/srp_role`,'get').then(response => response.data))
        },
        getRoles({commit}) {
            return new Promise(resolve => {
                request(`${process.env.VUE_APP_AUTH_URL}/role`, 'get')
                    .then(response => {
                        commit('setRoles', response.data);
                        resolve(response);
                    })
            })
        },
        async create(store, data) {
            return request(`${process.env.VUE_APP_AUTH_URL}/role`, 'post', { name: data.role.name, organisation_id: data.role.organisation_id })
                .then(response => {
                    store.commit('addRole', response.data);
                    request(`${process.env.VUE_APP_AUTH_URL}/role/${response.data.id}/permissions`, 'post', { permissions: data.permissions })
                        .then(() => {
                          store.dispatch('alert/alerts', { type: 'success', message: `Роль Факта "${response.data.name}" создана!` }, { root: true });
                        })
                });
        },
        delete({dispatch}, role) {
            return new Promise((resolve) => {
                request(`${process.env.VUE_APP_AUTH_URL}/role/${role.id}`, 'delete')
                    .then(response => {
                        dispatch('getRoles').then(() => {
                            dispatch('alert/alerts', { message: `Роль Факта "${role.name}" удалена!`, type: 'success' }, { root: true });
                            resolve(response)
                        });
                    })
            })
        },
        update({ dispatch }, data) {
          return new Promise((resolve, reject) => {
            request(`${process.env.VUE_APP_AUTH_URL}/role/${data.role.id}`, 'put', { name: data.role.name })
              .then(() => {
                request(`${process.env.VUE_APP_AUTH_URL}/role/${data.role.id}/permissions`, 'post', { permissions: data.active })
                  .then(() => {
                    dispatch('getRoles');
                    dispatch('alert/alerts', { message: `Роль Факта "${data.role.name}" отредактирована!`, type: 'success' }, { root: true });
                    resolve();
                  });
              })
              .catch((err) => {
                reject(err);
              })
          })
        },
        setPermissions(store, payload) {
          return request(`${process.env.VUE_APP_AUTH_URL}/role/${payload.roleId}/permissions`, 'post', { permissions: payload.permissions });
        },
        permissions(store,id) {
            return new Promise(resolve => {
                request(`${process.env.VUE_APP_AUTH_URL}/role/${id}/permissions`, 'get')
                    .then(response => {
                        resolve(response.data);
                    })
            })
        },
        restore(store, id) {
          return request(`${process.env.VUE_APP_AUTH_URL}/role/${id}/restore`, 'get');
        },
    },
    mutations: {
        setRolesSRP(state, roles) {
            state.roles_SRP = roles;
        },
        setRoles(state, roles) {
            state.roles = roles;
            state.roles.sort((a, b) => {
                let keyA = parseInt(a.id);
                let keyB = parseInt(b.id);
                if(keyA < keyB) return -1;
                if(keyA > keyB) return 1;
                return 0;
            })
        },
        addRole(state, role) {
            state.roles.push(role);
        },
        removeRole(state, id) {
            let removeIndex;
            state.roles.forEach((role, index) => {
                if(role.id === id)
                    removeIndex = index;
            });
            state.roles.splice(removeIndex, 1);
        },
        update(state, update) {
            let updateIndex;
            state.roles.forEach((role, index) => {
                if(role.id === update.id)
                    updateIndex = index;
            });
            state.roles.splice(updateIndex, 1, update);
            state.roles.sort((a, b) => {
                let keyA = parseInt(a.id);
                let keyB = parseInt(b.id);
                if(keyA < keyB) return -1;
                if(keyA > keyB) return 1;
                return 0;
            })
        }
    }
}
