<template>
  <RestyleModal color="red" @close="$emit('close')" @cancel="$emit('close')" @save="deleteAvatar()" :sendDisabled="send">
    <template slot="header">
      Удалить фото профиля
    </template>
    <div class="content-container">
      <img class="content-container__avatar" :src="avatar ? avatar : require('@/assets/icons/user/default_avatar.svg')" alt="avatar">
      <div class="content-top">Вы уверены, что хотите удалить
         <span style="font-weight: bold">фотографию профиля?</span>
      </div>
    </div>
    <template slot="confirmButtonText">
      Удалить
    </template>
  </RestyleModal>
</template>

<script>
import { mapGetters } from "vuex";
import { request } from "@/_helpers";

export default {
  name: 'DeletePhotoUserModal',
  props: {
   userID: {
      type: Number
   }
  },
  data() {
    return {
      send: false
    }
  },
  computed: {
   ...mapGetters({
      avatar: 'avatar/avatar',
   })
  },
  methods: {
      deleteAvatar() {
         if (this.userID) {
            this.send = true;
            request(`${process.env.VUE_APP_WN}/auth/avatar/delete`, 'delete')
            .then(() => {
               this.$store.dispatch('avatar/getAvatar');
               this.$store.dispatch(
                  "alert/alerts",
                  { message: `Фотография профиля удалена`, type: "success" },
               );
               this.$emit('close');
            }).finally(() => this.send = false);
         }
      },
   }
}
</script>

<style lang="scss" scoped>
  .content-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 800px;
    &__avatar {
      height: 200px;
      width: 200px;
      border-radius: 50%;
      margin-bottom: 25px;
    }
  }
  
  .content-top {
    font-weight: 500;
    font-size: 23px;
    line-height: 40px;
    color: $color-page-text;
  }

  .content-bottom {
    color: $color-button-text;
    font-weight: 700;
    font-size: 28px;
    line-height: 38px;
    overflow-wrap: break-word;
  }
</style>