<template>
  <div
    @click="switched()"
    @mouseenter="$emit('mouseenter', $event)"
    @mouseleave="$emit('mouseleave', $event)"
    :style="{ '--width': width }"
    :class="[switch_button? 'switch-checkbox--active': '', switch_button? `switch-checkbox-size-active--${size}`: '', `switch-checkbox-size--${size}` ]"
    class="switch-checkbox"
  >
    <div class="switch-checkbox-circle" :class="[`switch-checkbox-circle-size--${size}`]"></div>
  </div>
</template>

<script>
//Либо кладём в компонент v-model="var" и он автоматом меняется
//либо слушаем эмит switched и берём значения оттуда, не забывая передавать в props новое значение switch_button

export default {
  name: 'switch_checkbox',
  props: {
    switch_button: {
      default: true,
    },
    size: {
      type: String,
      default: 'normal',
    },
    width: {
      type: String,
      default: '80px',
    },
  },
  model: {
    prop: 'switch_button',
    event: 'switched'
  },
  methods: {
    switched() {
      if (typeof this.switch_button === 'number') {
        this.$emit('switched', Number(!this.switch_button))
      } else {
        this.$emit('switched', !this.switch_button)
      }
    }
  },
}
</script>



<style lang="scss" scoped>
  .switch-checkbox {
    padding: calc(var(--width) / 16) calc(var(--width) / 7.3);
    position: relative;
    width: var(--width);
    height: calc(var(--width) / 2);
    border: 2px solid #44FF8F;
    border-radius: calc(var(--width) / 1.6);
    line-height: 0 !important;
    cursor: pointer;

    &-size {
      &--small {
        padding: 3px 7px;
        width: 50px;
        height: 25px;
      }
      &--smallplus {
        padding: 3px 8px;
        width: 50px;
        height: 28px;
      }

      &--reworkOm {
        padding: 3px 8px;
        width: 56px;
        height: 28px;
        border: 2px solid #C3C9D2;
        background-color: #C3C9D2;
      }

      &-active {
        &--small {
          .switch-checkbox-circle {
            transform: translateX(20px) !important;
          }
        }
        &--smallplus {
          .switch-checkbox-circle {
            transform: translateX(15px) !important;
          }
        }
        &--reworkOm {
          .switch-checkbox-circle {
            transform: translateX(20px) !important;
          }
        }
      }
    }
    
    &-circle {
      display: inline-block;
      width: calc(var(--width) / 3);
      height: calc(var(--width) / 3);
      border-radius: 50%;
      background-color: #44FF8F;
      transition: transform 0.5s ease, background 0.5s ease;
      transform: translateX(0);

      &-size {
        &--small {
          width: 15px;
          height: 15px;
        }
        &--smallplus {
          width: 18px;
          height: 18px;
        }
        &--reworkOm {
          width: 18px;
          height: 18px;
          background-color: #F8FBFA;
        }
      }
    }
    
    &--active {
      border: 2px solid #44FF8F;
      background: #44FF8F;
      .switch-checkbox-circle {
        background-color: $color-page-background;
        transform: translateX(calc(var(--width) / 3));
      }
    }
  }
</style>
