export const rework_online_monitoring_filters = {
  namespaced: true,
  state: {
    dateOm: String,
    organisationOm: Number,
    wasteTypeOm: Number,
    autoUpdateOm: Boolean,
    saveDateOm: Boolean,
  },
  getters: {
    getFilterState: state => (key) => state[key],
  },
  actions: {
    setFilterState({commit}, payload) {
      commit('setFilterState', payload);
    },
  },
  mutations: {
    setFilterState(state, payload) {
      const { name, value } = payload

      state[name] = value
    },
  }
}