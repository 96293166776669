<template>
  <div class="all-links">
    <div
      id="links-reference"
      @mouseover="setIsHoverActive(true)"
      @mouseleave="setIsHoverActive(false)"
      class="all-link" 
      :class="{'all-link-active': this.$route.meta.active == 1}"
      v-if="
      userCan('admin') || userCan('superadmin')
      || userCan('organisation_read_all') || userCan('role_read_all')
      || userCan('user_read_all') || userCan('container_type_read_all')
      || userCan('container_platform_type_read_all') || userCan('failure_reason_read_all')
      || userCan('breakdowns_type_read_all') || userCan('payment_category_name_read_all')
      || userCan('work_order_cancelation_reason_read_all') || back_office"
    >
      <div class="link-flex">
          {{ getByHotSpotRouteTitleMenu('directories-menu') }}
          <div v-if="isActiveHotSpot('directories-menu')?.id" class="hotspot"
          @mouseenter="$store.dispatch('tooltip_store/SET_VIEW_TOOLTIP', {
              hoverEvent: $event,
              tooltipText: currentTooltipForHotSpot('directories-menu'),
              tooltipPosition: 'right'
            })" 
          @mouseleave="$store.dispatch('tooltip_store/CLEAR_VIEW_TOOLTIP');">
          Новое
          </div>
        </div>
      <img
        class="normal"
        src="@/assets/restyle/images/menu/chevron.svg"
        alt=""
      />
      <img
        class="hover"
        src="@/assets/restyle/images/menu/chevron-green.svg"
        alt=""
      />
      <reference :back_office="back_office" class="active-block" />
    </div>
    <div
      id="links-inventory"
      @mouseover="setIsHoverActive(true)"
      @mouseleave="setIsHoverActive(false)"
      class="all-link" 
      :class="{'all-link-active': this.$route.meta.active == 2}"
      v-if="userCan('container_platform_card_read_all') || userCan('container_card_read_all')"  
    >
        <div class="link-flex">
          {{ getByHotSpotRouteTitleMenu('inventory-menu') }}
          <div v-if="isActiveHotSpot('inventory-menu')?.id" class="hotspot"
          @mouseenter="$store.dispatch('tooltip_store/SET_VIEW_TOOLTIP', {
              hoverEvent: $event,
              tooltipText: currentTooltipForHotSpot('inventory-menu'),
              tooltipPosition: 'right'
            })" 
          @mouseleave="$store.dispatch('tooltip_store/CLEAR_VIEW_TOOLTIP');">
          Новое
          </div>
        </div>
      <img
        class="normal"
        src="@/assets/restyle/images/menu/chevron.svg"
        alt=""
      />
      <img
        class="hover"
        src="@/assets/restyle/images/menu/chevron-green.svg"
        alt=""
      />
      <inventory class="active-block" />
    </div>
    <div 
      id="links-fact"
      @mouseover="setIsHoverActive(true)"
      @mouseleave="setIsHoverActive(false)"
      class="all-link" 
      :class="{'all-link-active': this.$route.meta.active == 3}"
      v-if="userCan('monitoring_read') || userCan('vehicle_read_all') || back_office"
    >
        <div class="link-flex">
          {{ getByHotSpotRouteTitleMenu('fact-menu') }}
          <div v-if="isActiveHotSpot('fact-menu')?.id" class="hotspot"
          @mouseenter="$store.dispatch('tooltip_store/SET_VIEW_TOOLTIP', {
              hoverEvent: $event,
              tooltipText: currentTooltipForHotSpot('fact-menu'),
              tooltipPosition: 'right'
            })" 
          @mouseleave="$store.dispatch('tooltip_store/CLEAR_VIEW_TOOLTIP');">
          Новое
          </div>
        </div>
      <img
        class="normal"
        src="@/assets/restyle/images/menu/chevron.svg"
        alt=""
      />
      <img
        class="hover"
        src="@/assets/restyle/images/menu/chevron-green.svg"
        alt=""
      />
      <fact class="active-block" :back_office="back_office" />
    </div>
    <div 
      id="links-logistic"
      class="all-link" 
      :class="{'all-link-active': this.$route.meta.active == 4}" 
      v-if="userCan('show_logistic_menu')"
    >
        <div class="link-flex">
          {{ getByHotSpotRouteTitleMenu('logistic-menu') }}
          <div v-if="isActiveHotSpot('logistic-menu')?.id" class="hotspot"
          @mouseenter="$store.dispatch('tooltip_store/SET_VIEW_TOOLTIP', {
              hoverEvent: $event,
              tooltipText: currentTooltipForHotSpot('logistic-menu'),
              tooltipPosition: 'right'
            })" 
          @mouseleave="$store.dispatch('tooltip_store/CLEAR_VIEW_TOOLTIP');">
          Новое
          </div>
        </div>
      <img
        class="normal"
        src="@/assets/restyle/images/menu/chevron.svg"
        alt=""
      />
      <img
        class="hover"
        src="@/assets/restyle/images/menu/chevron-green.svg"
        alt=""
      />
      <logistic class="active-block" />
    </div>
    <div 
      v-if="userCan('show_reports_menu')"
      id="report-work-drivers"
      class="all-link" 
      :class="{'all-link-active': this.$route.meta.active == 6}" 
    >
        <div class="link-flex">
          {{ getByHotSpotRouteTitleMenu('reports-menu') }}
          <div v-if="isActiveHotSpot('reports-menu')?.id" class="hotspot"
          @mouseenter="$store.dispatch('tooltip_store/SET_VIEW_TOOLTIP', {
              hoverEvent: $event,
              tooltipText: currentTooltipForHotSpot('reports-menu'),
              tooltipPosition: 'right'
            })" 
          @mouseleave="$store.dispatch('tooltip_store/CLEAR_VIEW_TOOLTIP');">
          Новое
          </div>
        </div>
      <img
        class="normal"
        src="@/assets/restyle/images/menu/chevron.svg"
        alt=""
      />
      <img
        class="hover"
        src="@/assets/restyle/images/menu/chevron-green.svg"
        alt=""
      />
      <reports class="active-block"/>
    </div>
  </div>
</template>

<script>
import reference from "./blocks/reference";
import inventory from "./blocks/inventory";
import fact from "./blocks/fact";
import logistic from "./blocks/logistic";
import reports from "./blocks/reports.vue";
import { mapGetters, mapMutations } from "vuex";

export default {
  components: {
    reference,
    inventory,
    fact,
    logistic,
    reports,
  },
  computed: {
    ...mapGetters({
      userCan: "user/userCan",
      getByHotSpotRouteTitleMenu: "hotspots/getByHotSpotRouteTitleMenu",
      allListActiveHotSpots: "hotspots/allListActiveHotSpots",
    }),
    back_office() {
      return this.userCan('technical_department_root') || Boolean(process.env.VUE_APP_LOCAL_DEV);
    }
  },
  methods: {
    ...mapMutations({
      setIsHoverActive: 'hoverStateTopMenu/setIsHoverActive'
    }),
    isActiveHotSpot(menu) {
      const rusNameMenu = this.getByHotSpotRouteTitleMenu(menu)
      const result = this.allListActiveHotSpots.find(item => item.menu === rusNameMenu);
      return result;
    },
    currentTooltipForHotSpot(menu) {
      const hotSpot = this.isActiveHotSpot(menu);
      if (Object.entries(hotSpot).length &&  hotSpot.tooltip) {
        return hotSpot.tooltip
      } 
      return ''
    }
  }
};
</script>

<style lang="scss" scoped>
.all-links {
  margin-left: 85px;
  display: inline-flex;
  vertical-align: top;
  height: 100%;
}

.all-link {
  position: relative;
  padding: 14px 0;
  margin-right: 40px;
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;
  color: #ffffff;
  cursor: pointer;

  & img {
    margin-left: 10px;
  }

  .hover {
    display: none;
    transform: rotate(180deg);
  }

  .active-block {
    display: none;
  }

  &-active {
    color: #44ff8f;

    .normal {
      display: none;
    }

    .hover {
      display: initial;
      transform: rotate(0);
    }
  }

  &:hover {
    color: #44ff8f;

    .normal {
      display: none;
    }

    .hover {
      display: initial;
      transform: rotate(180deg);
    }

    .active-block {
      display: grid;
    }
  }
}

.link-flex {
  display: inline-flex;
  align-items: center
}
</style>